// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotifyVerification_section_tg3jR{display:grid;grid-auto-rows:min-content;row-gap:9px}.NotifyVerification_border_31EtX{display:grid;width:36px;height:36px;border:1px solid var(--supster-primary);border-radius:100%}.NotifyVerification_border_31EtX,.NotifyVerification_icon_3OdkY{justify-self:center}.NotifyVerification_icon_3OdkY{align-self:center;width:14px;height:14px;fill:var(--supster-primary)}.NotifyVerification_text_11dtw{justify-self:center;color:#a09f9f;font-weight:400;font-size:12px;line-height:18px;text-align:center}", ""]);
// Exports
exports.locals = {
	"section": "NotifyVerification_section_tg3jR",
	"border": "NotifyVerification_border_31EtX",
	"icon": "NotifyVerification_icon_3OdkY",
	"text": "NotifyVerification_text_11dtw"
};
module.exports = exports;
