// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IndividualDataVerification_form_2ZrbF{grid-template-rows:1fr auto}.IndividualDataVerification_content_CbCpO,.IndividualDataVerification_form_2ZrbF{display:grid;row-gap:15px}.IndividualDataVerification_content_CbCpO{grid-auto-rows:min-content}.IndividualDataVerification_select_3gZe2{font-weight:400!important}", ""]);
// Exports
exports.locals = {
	"form": "IndividualDataVerification_form_2ZrbF",
	"content": "IndividualDataVerification_content_CbCpO",
	"select": "IndividualDataVerification_select_3gZe2"
};
module.exports = exports;
