<template>
  <div :class="$style.section">
    <div :class="$style.border">
      <icon :class="$style.icon" name="shield" width="14" height="14" />
    </div>
    <p :class="$style.text" v-html="$t('verifications.notify')"></p>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NotifyVerification',
  components: {},
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 9px;
}

.border {
  display: grid;
  justify-self: center;
  width: 36px;
  height: 36px;
  border: 1px solid $primary;
  border-radius: 100%;
}

.icon {
  align-self: center;
  justify-self: center;
  width: 14px;
  height: 14px;
  fill: $primary;
}

.text {
  justify-self: center;
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
</style>
