// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VSteps_section_1_vDN{display:grid;grid-template-rows:auto 1fr;row-gap:10px}.VSteps_content_R4HAt,.VSteps_section_1_vDN{position:relative;width:100%;height:100%}.VSteps_steps_1kOBB{display:grid;grid-auto-flow:column}.VSteps_step_2L-Xj{width:0;height:6px;transition:width .3s}.VSteps_step_2L-Xj.VSteps_active_3utv0{width:100%;background-color:var(--supster-primary)}", ""]);
// Exports
exports.locals = {
	"section": "VSteps_section_1_vDN",
	"content": "VSteps_content_R4HAt",
	"steps": "VSteps_steps_1kOBB",
	"step": "VSteps_step_2L-Xj",
	"active": "VSteps_active_3utv0"
};
module.exports = exports;
