<template>
  <v-form :class="$style.form" color="primary" @submit="onSubmit">
    <div :class="$style.content">
      <text-input
        v-model="formData.last_name"
        :title="$t('surname')"
        :placeholder="$t('enter.surname')"
        :value="formData.last_name"
        :rules="rules.last_name"
      />
      <text-input
        v-model="formData.first_name"
        :title="$t('name')"
        :placeholder="$t('enter.name')"
        :value="formData.first_name"
        :rules="rules.first_name"
      />
      <text-input
        v-if="isCurrentCountryRu"
        v-model="formData.patronymic"
        :title="$t('patronymic')"
        :placeholder="$t('enter.patronymic')"
        :value="formData.patronymic"
        :rules="rules.patronymic"
      />
      <select-dropdown
        name="gender"
        icon-size="sm"
        :select-value="genderValue"
        :title="$t('gender')"
        :class-name="$style.select"
        :list="genderList"
        :rules="rules.sex"
        @change="formData.sex = $event.name"
      />
      <select-dropdown
        name="countries"
        icon-size="sm"
        :select-value="countryValue"
        :title="$t('country')"
        :class-name="$style.select"
        :list="countries"
        :rules="rules.country_id"
        :is-loading="!countries.length"
        is-search
        @change="formData.country_id = $event.id"
      >
        <template #no-data> {{ $t('no.countries') }} </template>
      </select-dropdown>
      <select-dropdown
        name="document-type"
        icon-size="sm"
        :select-value="documentValue"
        :title="$t('documentType')"
        :class-name="$style.select"
        :list="documentTypesList"
        :rules="rules.document_type"
        @change="formData.document_type = $event.name"
      />
    </div>
    <template #button>
      {{ $t('next') }}
    </template>
  </v-form>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VForm from '@elements/VForm.vue'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'

import { vLength, vLetters, vName, vRequired, vSurname } from '@utils/validations'

export default Vue.extend({
  name: 'IndividualDataVerification',
  components: {
    VForm,
    TextInput,
    SelectDropdown,
  },
  props: {
    index: Number,
    data: {
      type: Object,
      default: () => ({}),
    },
    isStepBack: Boolean,
  },
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        patronymic: '',
        sex: null,
        country_id: null,
        document_type: null,
      },
    }
  },
  computed: {
    ...mapGetters('Countries', ['countries', 'isCurrentCountryRu']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Verification', ['verification', 'step']),
    rules() {
      return {
        first_name: [vRequired(), vName(), vLength(2, 30)],
        last_name: [vRequired(), vSurname(), vLength(2, 30)],
        patronymic: [vRequired(), vLetters(), vLength(2, 30)],
        sex: [vRequired()],
        country_id: [vRequired()],
        document_type: [vRequired()],
      }
    },
    genderList() {
      return [
        {
          value: this.$t('genders.male'),
          name: 1,
        },
        {
          value: this.$t('genders.female'),
          name: 2,
        },
      ]
    },
    genderValue() {
      return this.isStepBack
        ? this.genderList.find(({ name }) => name === this.verification.sex)?.value
        : this.formData.sex
    },
    documentTypesList() {
      return [
        {
          value: this.$t('documents.passport'),
          name: 1,
        },
        {
          value: this.$t('documents.idCard'),
          name: 2,
        },
        {
          value: this.$t('documents.driversLicense'),
          name: 3,
        },
      ]
    },
    documentValue() {
      return this.isStepBack
        ? this.documentTypesList.find(({ name }) => name === this.verification.document_type)?.value
        : this.formData.document_type
    },
    countryValue() {
      return this.isStepBack
        ? this.countries.find(({ id }) => id === this.verification.country_id)?.value
        : this.formData.country_id
    },
  },
  methods: {
    ...mapMutations('Verification', ['setVerificationData', 'setStep']),
    customize(objValue, _, name) {
      if (['first_name', 'patronymic', 'last_name'].includes(name) && objValue === null) {
        objValue = ''
        return objValue
      }
      if (name === 'country_id' && objValue.length === 0) {
        return null
      }
      return objValue
    },
    onSubmit() {
      this.setVerificationData(this.formData)
      this.setStep(this.isStepBack ? this.step : this.index + 1)
      this.$emit('submit')
    },
  },
})
</script>
<style lang="scss" module>
.form {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 15px;
}

.content {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 15px;
}

.select {
  font-weight: 400 !important;
}
</style>
