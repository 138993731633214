// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DocumentVerification_section_15AEP{grid-template-rows:1fr auto;height:100%}.DocumentVerification_content_xQMnh,.DocumentVerification_section_15AEP{display:grid;row-gap:15px}.DocumentVerification_content_xQMnh{grid-auto-rows:min-content}.DocumentVerification_content_xQMnh.DocumentVerification_padding_3KKdk{padding-top:45px}.DocumentVerification_icon_1m2xN{width:72px;height:96px;fill:var(--supster-primary)}.DocumentVerification_icon_1m2xN,.DocumentVerification_text_2SU8E{justify-self:center}.DocumentVerification_text_2SU8E{color:#a09f9f;font-weight:400;font-size:12px;line-height:18px;text-align:center}.DocumentVerification_attention_1wY10,.DocumentVerification_text_2SU8E{align-self:center}.DocumentVerification_attention_1wY10{width:14px;height:12px;margin-right:4px;fill:#000}.DocumentVerification_file_124XF{position:relative;display:flex;padding:7px;background:transparent!important;border:1px dashed var(--supster-primary)!important;border-radius:6px;cursor:pointer}.DocumentVerification_file_124XF:disabled{cursor:not-allowed;opacity:.3}.DocumentVerification_fileInput_Xpqvv{position:absolute;z-index:-1;width:.1px;height:.1px;overflow:hidden;opacity:0}.DocumentVerification_fileButton_31GTt{position:absolute;top:5px;right:7px;width:auto}.DocumentVerification_fileIcon_1wHbZ{width:12px;height:12px;color:currentColor}.DocumentVerification_fileText_oi7Wj{width:90%;overflow:hidden;color:#000;font-weight:500;font-size:15px;line-height:22px;white-space:nowrap;text-align:center;text-overflow:ellipsis}.DocumentVerification_fileTextFullSize_MQ0vW{width:100%}", ""]);
// Exports
exports.locals = {
	"section": "DocumentVerification_section_15AEP",
	"content": "DocumentVerification_content_xQMnh",
	"padding": "DocumentVerification_padding_3KKdk",
	"icon": "DocumentVerification_icon_1m2xN",
	"text": "DocumentVerification_text_2SU8E",
	"attention": "DocumentVerification_attention_1wY10",
	"file": "DocumentVerification_file_124XF",
	"fileInput": "DocumentVerification_fileInput_Xpqvv",
	"fileButton": "DocumentVerification_fileButton_31GTt",
	"fileIcon": "DocumentVerification_fileIcon_1wHbZ",
	"fileText": "DocumentVerification_fileText_oi7Wj",
	"fileTextFullSize": "DocumentVerification_fileTextFullSize_MQ0vW"
};
module.exports = exports;
