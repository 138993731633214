<template>
  <div :class="$style.section">
    <div :class="[$style.content, $style.padding]">
      <icon v-if="icon" :class="$style.icon" :name="icon" />
      <slot />
      <p :class="$style.text">{{ $t('files.format') }}: {{ $t('files.document', ['5 Мb']) }}</p>
      <v-file
        :isDisabled="isStepBack"
        :class="[$style.file, $style.button]"
        @files="onFile"
        accept="image/png, image/jpeg, application/pdf"
      >
        <transition name="slide-up" mode="out-in">
          <p
            :key="!!currentFile"
            :class="[$style.fileText, { [$style.fileTextFullSize]: !currentFile }]"
          >
            {{ currentFile ? currentFile.name : $t('files.add') }}
          </p>
        </transition>
        <transition name="fade" mode="out-in">
          <v-button
            v-if="currentFile"
            :class="$style.fileButton"
            size="sm"
            color="transparent"
            @click.stop.prevent="currentFile = null"
          >
            <icon name="close" :class="$style.fileIcon" />
          </v-button>
        </transition>
      </v-file>
    </div>
    <div :class="$style.content">
      <transition name="fade">
        <p v-if="!isStepBack && !currentFile" :class="$style.text">
          <icon :class="$style.attention" name="attention-bold" /> {{ $t('files.noUploaded') }}
        </p>
      </transition>
      <v-button
        size="lg"
        color="primary"
        :is-disabled="!isStepBack ? !currentFile : false"
        :is-loading="isLoading"
        @click="onSubmit"
      >
        {{ $t('confirm.verify') }}
      </v-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import VButton from '@elements/VButton.vue'
import VFile from '@elements/VFile.vue'

import { isFileValidateFullType } from '@utils/files'

export default Vue.extend({
  name: 'DocumentVerification',
  components: { VButton, VFile },
  props: {
    step: Number,
    index: Number,
    icon: {
      require: true,
      type: String,
    },
    isStepBack: Boolean,
  },
  data() {
    return {
      currentFile: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Files', ['filesValid', 'files']),
    ...mapGetters('Media', ['mediaIds']),
    ...mapGetters('Verification', ['verification', 'verificationData']),
    media() {
      return this.verification?.media_objects ?? []
    },
  },
  methods: {
    ...mapMutations('Verification', ['setVerificationData', 'setStep']),
    ...mapActions('Media', ['postMedia']),
    onFile() {
      const isValid = isFileValidateFullType(
        this.files[0].data,
        ['image/png', 'image/jpeg', 'application/pdf'],
        5000000
      )
      if (!isValid) {
        this.$notify({
          type: 'error',
          title: `${this.$t('errors.files.caption')}`,
          text: `${this.$t('errors.files.description', [this.locale === 'ru' ? '5Мб' : '5Mb'])}`,
        })
        return
      }
      this.currentFile = this.files[0].data
    },

    async onSubmit() {
      this.isLoading = true
      if (this.isStepBack) {
        this.$emit('submit')
        return
      }
      try {
        await this.postMedia()
        if (this.step === 4) {
          this.setVerificationData({
            ...this.verificationData,
            code_media_id: this.mediaIds[this.mediaIds.length - 1],
          })
        } else {
          this.setVerificationData({
            ...this.verificationData,
            media_objects: this.mediaIds,
          })
        }

        this.setStep(this.index + 1)

        this.$emit('submit')
      } catch (error) {
        this.$notifyServerError(error)
      }
      this.isLoading = false
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  row-gap: 15px;
}

.content {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 15px;

  &.padding {
    padding-top: 45px;
  }
}

.icon {
  justify-self: center;
  width: 72px;
  height: 96px;
  fill: $primary;
}

.text {
  align-self: center;
  justify-self: center;
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.attention {
  align-self: center;
  width: 14px;
  height: 12px;
  margin-right: 4px;
  fill: black;
}

.file {
  position: relative;
  display: flex;
  padding: 7px;
  background: transparent !important;
  border: 1px dashed $primary !important;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &Input {
    position: absolute;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    opacity: 0;
  }

  &Button {
    position: absolute;
    top: 5px;
    right: 7px;
    width: auto;
  }

  &Icon {
    width: 12px;
    height: 12px;
    color: currentColor;
  }

  &Text {
    width: 90%;
    overflow: hidden;
    color: black;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;

    &FullSize {
      width: 100%;
    }
  }
}
</style>
