<template>
  <section :class="$style.section">
    <v-steps v-if="!isFinally || verifDraft" :class="$style.steps" :active-index="index" :steps="5">
      <template #step-0>
        <div :class="$style.container">
          <div :class="$style.content">
            <div :class="$style.wrapper">
              <icon :class="$style.icon" name="check-pattern-lg" />
              <h3 :class="$style.title">{{ $t('verifications.step-1.title') }}</h3>
              <p :class="$style.text">
                {{ $t('verifications.step-1.text') }}
              </p>
            </div>
            <notify-verification />
          </div>
          <v-button size="lg" color="primary" @click="onChangeStep">
            {{ $t('getStarted') }}
          </v-button>
        </div>
      </template>
      <template #step-1>
        <div :class="[$style.container, $style.noGap]">
          <individual-data-verification
            :index="index"
            :is-step-back="isStepBack"
            @submit="index++"
          />
        </div>
      </template>
      <template v-for="step of [2, 3, 4]" v-slot:[getSlotName(step)]>
        <div :class="[$style.container, $style.noGap]" :key="`steps-${step}`">
          <document-verification
            :step="step"
            :index="index"
            :icon="getIconName(step)"
            :is-step-back="isStepBack"
            @submit="step === 3 ? createVerification() : index++"
          >
            <svg
              v-if="step === 2"
              :class="$style.icon"
              viewBox="0 0 96 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <!-- eslint-disable -->
              <path
                d="M49 2C49 1.44772 48.5523 1 48 1V1C47.4477 1 47 1.44771 47 2V70C47 70.5523 47.4477 71 48 71V71C48.5523 71 49 70.5523 49 70V2Z"
                :fill="appColor()"
              />
              <path
                d="M17 16C17 14.8954 17.8954 14 19 14H30C31.1046 14 32 14.8954 32 16V32C32 33.1046 31.1046 34 30 34H19C17.8954 34 17 33.1046 17 32V16Z"
                :fill="appColor()"
              />
              <path
                d="M38 41C38 40.4477 37.5523 40 37 40H12C11.4477 40 11 40.4477 11 41V41C11 41.5523 11.4477 42 12 42H37C37.5523 42 38 41.5523 38 41V41Z"
                :fill="appColor()"
              />
              <path
                d="M14 47C14 46.4477 14.4477 46 15 46H34C34.5523 46 35 46.4477 35 47V47C35 47.5523 34.5523 48 34 48H15C14.4477 48 14 47.5523 14 47V47Z"
                :fill="appColor()"
              />
              <path
                d="M32 53C32 52.4477 31.5523 52 31 52H18C17.4477 52 17 52.4477 17 53V53C17 53.5523 17.4477 54 18 54H31C31.5523 54 32 53.5523 32 53V53Z"
                :fill="appColor()"
              />
              <path
                d="M58 41C58 40.4477 58.4477 40 59 40H84C84.5523 40 85 40.4477 85 41V41C85 41.5523 84.5523 42 84 42H59C58.4477 42 58 41.5523 58 41V41Z"
                :fill="appColor()"
              />
              <path
                d="M85 15C85 14.4477 84.5523 14 84 14H59C58.4477 14 58 14.4477 58 15V15C58 15.5523 58.4477 16 59 16H84C84.5523 16 85 15.5523 85 15V15Z"
                :fill="appColor()"
              />
              <path
                d="M58 47C58 46.4477 58.4477 46 59 46H78C78.5523 46 79 46.4477 79 47V47C79 47.5523 78.5523 48 78 48H59C58.4477 48 58 47.5523 58 47V47Z"
                :fill="appColor()"
              />
              <path
                d="M79 21C79 20.4477 78.5523 20 78 20H59C58.4477 20 58 20.4477 58 21V21C58 21.5523 58.4477 22 59 22H78C78.5523 22 79 21.5523 79 21V21Z"
                :fill="appColor()"
              />
              <path
                d="M58 53C58 52.4477 58.4477 52 59 52H72C72.5523 52 73 52.4477 73 53V53C73 53.5523 72.5523 54 72 54H59C58.4477 54 58 53.5523 58 53V53Z"
                :fill="appColor()"
              />
              <path
                d="M73 27C73 26.4477 72.5523 26 72 26H59C58.4477 26 58 26.4477 58 27V27C58 27.5523 58.4477 28 59 28H72C72.5523 28 73 27.5523 73 27V27Z"
                :fill="appColor()"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 6C0 2.68629 2.68629 0 6 0H90C93.3137 0 96 2.68629 96 6V66C96 69.3137 93.3137 72 90 72H6C2.68629 72 0 69.3137 0 66V6ZM6 2H90C92.2091 2 94 3.79086 94 6V66C94 68.2091 92.2091 70 90 70H6C3.79086 70 2 68.2091 2 66V6C2 3.79086 3.79086 2 6 2Z"
                :fill="appColor()"
              />
              <!-- eslint-enable -->
            </svg>
            <h3 :class="$style.title">{{ $t(`verifications.step-${step}.title`) }}</h3>
            <v-button v-if="step === 4" :class="$style.button" color="primary" size="lg" is-div>
              {{ code }}
            </v-button>
            <p :class="$style.text">
              {{ $t(`verifications.step-${step}.text`) }}
            </p>
          </document-verification>
        </div>
      </template>
      <template #step-5>
        <div :class="$style.container">
          <div :class="$style.content">
            <div :class="$style.wrapper">
              <icon :class="$style.icon" name="check-pattern-lg" />
              <h3 :class="$style.title">{{ $t('verifications.step-5.title') }}</h3>
              <p :class="$style.text">
                {{ $t('verifications.step-5.text') }}
              </p>
              <v-link
                :to="{
                  name: 'Documents',
                  params: {
                    slug: sellerDocId,
                  },
                }"
                :class="$style.link"
                type="default"
                size="lg"
              >
                {{ $t('termsService') }}
              </v-link>
            </div>
            <notify-verification />
          </div>
          <v-button size="lg" color="primary" @click="updateVerification"
            >{{ $t('confirm.verify') }}
          </v-button>
        </div>
      </template>
    </v-steps>
    <div v-else :class="$style.finally">
      <p :class="[$style['info-text'], $style['info-block']]">
        {{ $t('verifications.inProgress.topText') }}
      </p>
      <div :class="$style['card-wrap']">
        <div :class="$style.card">
          <div>
            <h3 :class="$style['card-title']">
              {{ $t('verifications.inProgress.step1.title') }}
            </h3>
            <p :class="$style['card-text']">
              {{ $t('verifications.inProgress.complete') }}
            </p>
          </div>
          <div :class="$style['card-icon-wrap']">
            <icon :class="$style['card-icon']" name="check-pattern-lg" />
          </div>
        </div>
        <div v-if="verifReject" :class="[$style.card, $style['card-rejected']]">
          <div :class="$style['card-rejected-top']">
            <h3 :class="$style['card-title']">
              {{ $t('verifications.inProgress.step2.title') }}
            </h3>
            <div :class="$style['card-btn']">
              <icon :class="$style['card-btn-icon']" name="warning" />
              <span>{{ $t('verifications.inProgress.rejected') }}</span>
            </div>
          </div>
          <div>
            <div :class="[$style['card-reason-item'], $style['mb12']]">
              <span>{{ $t('verifications.inProgress.reason') }}</span>
              <p>{{ verification.errorReason }}</p>
            </div>
            <div :class="$style['card-reason-item']">
              <span>{{ $t('verifications.inProgress.comment') }}</span>
              <p>{{ verification.errorDesc }}</p>
            </div>
          </div>
        </div>
        <div v-else :class="$style.card">
          <div>
            <h3 :class="$style['card-title']">
              {{ $t('verifications.inProgress.step2.title') }}
            </h3>
            <p :class="$style['card-text']">
              {{ $t('verifications.inProgress.complete') }}
            </p>
          </div>
          <div v-if="verifComplete" :class="$style['card-icon-wrap']">
            <icon :class="$style['card-icon']" name="check-pattern-lg" />
          </div>
          <div v-else :class="$style['card-btn']">
            <span>{{ $t('verifications.inProgress.wait') }}</span>
          </div>
        </div>
        <div v-if="verifComplete" :class="$style.card">
          <div>
            <h3 :class="$style['card-title']">
              {{ $t('verifications.inProgress.step3.title') }}
            </h3>
            <p :class="$style['card-text']">
              {{ $t('verifications.inProgress.complete') }}
            </p>
          </div>
          <div :class="$style['card-icon-wrap']">
            <icon :class="$style['card-icon']" name="check-pattern-lg" />
          </div>
        </div>
      </div>
      <div :class="$style['info-block']">
        <icon :class="$style['info-icon']" name="shield-rounded" />
        <p :class="[$style['info-text'], verifReject && $style['info-text-reject']]">
          {{ $t('verifications.inProgress.bottomText') }}
        </p>
        <v-button
          @click="setStep(1)"
          v-if="verifReject"
          :class="[$style.button, $style['info-btn']]"
          color="primary"
          size="lg"
          is-div
        >
          {{ $t('verifications.inProgress.repeatModerate') }}
        </v-button>
      </div>
    </div>
    <overlay-content-loader :is-loading="pageLoading" :delay="0" />
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VLink from '@elements/VLink.vue'
import OverlayContentLoader from '@loaders/list/OverlayContentLoader.vue'
import VSteps from '@layouts/VSteps.vue'
import VButton from '@elements/VButton.vue'
import NotifyVerification from './list/NotifyVerification.vue'
import IndividualDataVerification from './list/IndividualDataVerification.vue'
import DocumentVerification from './list/DocumentVerification.vue'

export default Vue.extend({
  name: 'Verification',
  components: {
    VSteps,
    VLink,
    VButton,
    NotifyVerification,
    IndividualDataVerification,
    DocumentVerification,
    OverlayContentLoader,
  },
  data() {
    return {
      index: this.step,
    }
  },
  computed: {
    ...mapGetters('App', ['appColor']),
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Countries', ['countries', 'sellerDocId']),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Verification', [
      'verification',
      'verificationData',
      'step',
      'code',
      'verificationId',
      'mode',
    ]),
    isStepBack() {
      return this.verification && this.step > this.index
    },
    isFinally() {
      return this.verification && this.step > 5
    },
    verifNew() {
      return this.verification?.status === 1
    },
    verifComplete() {
      return this.verification?.status === 2
    },
    verifReject() {
      return this.verification?.status === 3
    },
    verifDraft() {
      return this.verification?.status === 4
    },
  },
  watch: {
    step: {
      async handler(step) {
        this.index = step
      },
      immediate: true,
    },
  },
  created() {
    this.setPageTitle(
      this.verification
        ? { name: 'verification' }
        : { name: this.$t('verifications.inProgress.title') }
    )
  },
  async mounted() {
    if (!this.verification) {
      await this.getVerifications()
    }

    if (this.verification && this.verifDraft) {
      this.setMode('edit')
    } else if (this.verification && this.verifComplete) {
      this.setStep(6)
    } else if (this.verification && (this.verifReject || this.verifNew)) {
      this.setMode('edit')
      this.setStep(6)
    }

    if (!this.countries) {
      await this.getCountries()
    }
    this.$bus.$on('emit-back', () => {
      if (this.index > 0) {
        this.index -= 1
      }
    })
  },
  methods: {
    ...mapActions('Verification', ['getVerifications', 'postVerification', 'putVerification']),
    ...mapActions('Countries', ['getCountries']),
    ...mapMutations('Page', ['setPageTitle']),
    ...mapMutations('Verification', ['resetVerification', 'setStep', 'setMode']),
    onChangeStep() {
      if (this.isStepBack) {
        this.index += 1
        return
      }
      this.setStep(this.index + 1)
    },
    async createVerification() {
      if (this.mode === 'edit') {
        await this.putVerification({
          id: this.verificationId,
          verification: { ...this.verificationData, status: 4 },
        })
      } else {
        await this.postVerification(this.verificationData)
      }
    },
    async updateVerification() {
      await this.putVerification({
        id: this.verificationId,
        verification: { ...this.verificationData, status: 1 },
      })
      this.setStep(6)
    },
    getSlotName(step) {
      return `step-${step}`
    },
    getIconName(step) {
      if (step === 2) {
        return null
      }
      return step === 3 ? 'youAndDocument' : 'confirmationCode'
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  width: 100%;
  height: 100%;
}

.steps {
  row-gap: 0;
}

.container {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: 21px;
  row-gap: 15px;

  &.noGap {
    row-gap: 0;
  }
}

.content {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 15px;
}

.wrapper {
  display: grid;
  grid-auto-rows: min-content;
  padding-top: 45px;
  row-gap: 15px;
}

.icon {
  justify-self: center;
  width: 90px;
  height: 90px;
  fill: $primary;
}

.title {
  justify-self: center;
  max-width: 310px;
  color: black;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.text {
  justify-self: center;
  max-width: 264px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.button {
  justify-self: center;
  width: auto !important;
  margin: 0 15px;
}

.link {
  justify-self: center;
  width: auto;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.card {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 96px;
  margin-bottom: 6px;
  padding: 15px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);

  &-wrap {
    width: 100%;
  }

  &-rejected {
    grid-template-rows: auto auto;
    grid-template-columns: auto;

    &-top {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $secondary-light;

      .card-btn {
        width: auto;
        padding-left: 9px;
        color: #fff;
        background: $error;
      }
    }
  }

  &-reason {
    &-item {
      display: grid;
      grid-auto-rows: min-content;
      grid-template-columns: 96px auto;

      span {
        color: $secondary-medium;
        font-size: 12px;
        line-height: 18px;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        word-break: break-all;
      }

      &.mb12 {
        margin-bottom: 12px;
      }
    }
  }

  &-title {
    margin-bottom: 6px;
    padding-right: 15px;
    color: black;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &-text {
    color: black;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 24px;
    padding: 3px 9px 3px 9px;
    background: $primary;
    border-radius: 12px;

    svg {
      width: 14px;
      margin-right: 5px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      white-space: nowrap;
    }
  }

  &-icon {
    width: 51px;
    height: 51px;
    fill: $primary;

    &-wrap {
      justify-self: center;
    }
  }
}

.info {
  &-block {
    width: 100%;
    text-align: center;
  }

  &-icon {
    width: 36px;
    height: 36px;
    margin-bottom: 9px;
    fill: transparent;
  }

  &-btn {
    margin-bottom: 21px;
    cursor: pointer !important;
  }

  &-text {
    max-width: 318px;
    margin: 0 auto 21px;
    color: $secondary-medium;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    &-reject {
      margin-bottom: 15px;
    }
  }
}

.finally {
  display: grid;
  grid-auto-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 21px 6px 0;
}
</style>
