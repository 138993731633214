<template>
  <div ref="steps" :class="$style.section">
    <div :class="$style.steps">
      <span
        v-for="(_, index) of steps"
        :key="`step-${index}`"
        :class="[$style.step, { [$style.active]: activeIndex >= ++index }]"
      >
      </span>
    </div>
    <div :class="$style.content">
      <slot :name="`step-${activeIndex <= steps && activeIndex >= 0 ? activeIndex : 0}`" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VSteps',
  props: {
    steps: {
      require: true,
      type: Number,
    },
    activeIndex: {
      default: 0,
      type: Number,
    },
  },
  watch: {
    activeIndex: {
      immediate: true,
      handler(index) {
        if (index >= 0 && index <= this.steps) {
          return
        }
        throw Error(`Expected Number from 0 to ${this.steps}`)
      },
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  row-gap: 10px;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.steps {
  display: grid;
  grid-auto-flow: column;
}

.step {
  width: 0;
  height: 6px;
  transition: width $transition;

  &.active {
    width: 100%;
    background-color: $primary;
  }
}
</style>
